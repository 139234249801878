import { default as CardBase } from "@/Components_DEP/Card";
import SplitCardHeader from "@/Components_DEP/Card/SplitCardHeader";
import Context from "@/Context";

import { money } from "@/utils/helpers";
import styled from "@emotion/styled";
import { useContext } from "react";
import { NYCZipcodes } from "@/utils/stateCalculations/utilFuncs";

function LifetimeSavings() {
  const { proposal } = useContext(Context);
  const {
    financing_term,
    financing_interest_rate,
    incentive_rebate = 0,
    type = "",
    property_state,
    property_zip,
    battery_no_net_meter,
    battery_cost,
  } = proposal || {};

  const isRoofing = type === "DecoLoan" || type === "DecoCash"; //type === 'roofing'
  const isEFS =
    proposal?.financing_financial_partner === "EFS" ||
    proposal?.financing_financial_partner === "EFSBridge";
  return (
    <Card>
      <SplitCardHeader
        label1="Solar Loan Overview"
        label2="Estimated Lifetime Savings"
      />
      <div className="content-wrapper">
        <div className="loan-overview">
          <p className="label">Total System Cost</p>
          <div className="row">
            <p className="value">
              {/* {isRoofing
                ? money(getSolarLoan(proposal))
                : */}
              {money(
                (proposal?.system_epc || 0) + (proposal?.battery_cost || 0)
              )}
              {/* } */}
            </p>
          </div>
          <div className="line" />

          {incentive_rebate > 0 && (
            <>
              <p className="label">Rebate</p>
              <div className="row">
                <p className="value">{money(incentive_rebate)}</p>
              </div>
              <div className="line" />
            </>
          )}
          {isEFS ? (
            <>
              <p className="label">
                Solar Loan{battery_cost && ", Battery Loan,"} & Term
              </p>
              <div className="row">
                <p className="value">
                  {money(+(proposal?.financing_amount || 0))}
                </p>
                {battery_cost && (
                  <p className="value">{money(+(battery_cost || 0))}</p>
                )}
                <p className="value">{financing_term}yrs</p>
                <p className="value">
                  {money(proposal?.financing_monthly_loan_payment || 0)}/mo.*
                </p>
              </div>
              <p
                className="footer"
                style={{ fontSize: 10, width: "100%", textAlign: "start" }}
              >
                *This monthly payment assumes financing of a $150 loan
                application fee
              </p>
              <div className="line" />
              <p className="label">
                EFS Bridge Loan & Term <span>(due by Month 24)</span>
              </p>
              <div className="row">
                <p className="value">
                  {money(proposal?.financing_bridge_loan_amount)}
                </p>
                <p className="value">
                  {money(proposal?.financing_balloon_payment_amount)}
                </p>
              </div>
              <div className="line" />
              <p className="label">Solar Loan Payments & Interest Rate</p>
              <div className="row">
                <p className="value">
                  {proposal?.financing_number_of_loan_payments || 0}
                </p>
                <p className="value">{financing_interest_rate}% APR</p>
              </div>
            </>
          ) : (
            <>
              <p className="label">
                Solar Loan{battery_cost && ", Battery Loan,"} & Term
              </p>
              <div className="row">
                <p className="value">
                  {money(+(proposal?.financing_amount || 0))}
                </p>
                {battery_cost && (
                  <p className="value">{money(+(battery_cost || 0))}</p>
                )}
                <p className="value">{financing_term}yrs</p>
              </div>
              <div className="line" />
              <p className="label">Solar Loan Payments & Interest Rate</p>
              <div className="row">
                <p className="value">
                  {proposal?.financing_number_of_loan_payments || 0}
                </p>
                <p className="value">{financing_interest_rate}% APR</p>
              </div>
            </>
          )}
        </div>
        <div className="vertical-line" />
        <div className="estimated-savings">
          <h5>{money(proposal?.estimated_lifetime_savings || 0)}</h5>
          <div className="line" />
          <div className="estimated-savings-details">
            <div>
              <p className="label">Estimated ITC</p>
              <h6>
                {money(
                  (proposal?.incentive_itc || 0) +
                    (proposal?.battery_cost || 0) * 0.3
                )}
              </h6>
            </div>
            {/* {getStateTaxCredit(proposal)  > 0 && ( */}
            {(proposal?.incentive_state_tax_credit || 0) > 0 && (
              <div>
                <p className="label">{property_state} State Tax Credit</p>
                <h6>{money(proposal?.incentive_state_tax_credit || 0)}</h6>
              </div>
            )}
          </div>
          {property_state === "NY" &&
            NYCZipcodes.includes(+(property_zip || 0)) && (
              <div
                className="estimated-savings-details"
                style={{ marginTop: "8px" }}
              >
                <div>
                  <p className="label">NYC Tax Abatement</p>
                  <h6>
                    {money(proposal?.incentive_property_tax_abatement || 0)}
                  </h6>{" "}
                  <p className="subtext">
                    Payout split into four annual payments of{" "}
                    {money(
                      (proposal?.incentive_property_tax_abatement || 0) / 4
                    )}
                  </p>
                </div>
              </div>
            )}
        </div>
      </div>
      <div className="row">
        <p className="footer">
          The payment amount disclosed assumes that you make your Incentive
          Payment as provided in your loan agreement. If you do no make the
          Incentive Payment, your payment amount will increase. Please see the
          terms of your loan agreement for additional details regarding the
          timing and amount of the incentive.
        </p>
        <p className="footer">
          Tax credits, rebates and incentives may vary depending on your
          circumstances. We do not provide tax advice and recommend you consult
          with your tax professional for guidance.
        </p>
      </div>
    </Card>
  );
}

export default LifetimeSavings;

const Card = styled(CardBase)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    height: 100%;
    margin: 0px 14px;
    display: flex;
    align-items: center;

    p.label {
      font-family: Gotham Medium;
      font-size: 14px;
    }

    p.value {
      font-family: Gotham Medium;
      color: #c6c6c6;
      font-size: 16px;
    }

    p > span {
      color: #c6c6c6;
      font-size: 12px;
    }
  }

  .loan-overview {
    width: calc(50% - 1px - 2em);
    margin-right: 2em;
  }

  .estimated-savings {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h5 {
      color: #6ee2ff;
      font-size: 42px;
      margin-bottom: 30px;
    }

    h6 {
      color: #6ee2ff;
    }
  }

  .estimated-savings-details {
    display: flex;

    gap: 1em;
    justify-content: space-between;
    text-align: center;
  }

  .row {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }

  .footer {
    text-align: center;
    width: 50%;
    font-size: 7px;
    color: #a5a5a5;
    margin: 0em 2em;
  }

  .line {
    width: 85%;
    height: 1px;
    background: #dbdbdb;
    margin: 0.3em 0px;
  }
  .vertical-line {
    height: 90%;
    width: 1px;
    background: #dbdbdb;
  }
  .subtext {
    font-size: 12px;
  }
`;
