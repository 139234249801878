import { default as CardBase } from "@/Components_DEP/Card";
import CardHeader from "@/Components_DEP/Card/CardHeader";
import Context from "@/Context";
import {} from "@/utils/calculations";
import { money } from "@/utils/helpers";
import styled from "@emotion/styled";
import { useContext } from "react";
import InfoBar from "./InfoBar";
import InfoRow from "./InfoRow";

function SavingsByTheNumbers() {
  const { proposal } = useContext(Context);
  const { utility_rate, financing_solar_rate, property_state, type } =
    proposal || {};

  const utilityBill =
    (proposal?.utility_old_monthly_utility_bill || 0) *
    (proposal?.property_state === "FL" ? 12 : 1);

  const totalCostWithSolar =
    (proposal?.ms_solar_bill || 0) + +(proposal?.utility_bill_ms || 0);
  return (
    <Card>
      <CardHeader label="Your Path to Savings by the Numbers" />
      <div className="content-wrapper">
        <div className="table">
          <div />
          <p className="column-head">Your Local Utility</p>
          <p className="column-head" style={{ transform: "translate(-56px)" }}>
            Momentum Solar{type !== "MSES" && " + IGS"}
          </p>
          <p>Utility Rate</p>
          <p className="cell" style={{ textAlign: "start" }}>
            ${utility_rate?.toFixed(3)}/kWh
          </p>
          <p className="cell" style={{ textAlign: "start" }}>
            ${financing_solar_rate?.toFixed(3)}/kWh
          </p>
          <p>Utility Bill</p>
          <p className="light cell">{money(utilityBill)}</p>
          <p className="light cell">{money(proposal?.utility_bill_ms)}</p>
          <p>Solar Bill</p>
          <p className="light cell">$0.00</p>
          <p className="light cell">{money(proposal?.ms_solar_bill)}</p>
          <div className="line" />
          <p>Total cost:</p>
          <p className="cell">{money(utilityBill)}</p>
          <p className="cell">{money(totalCostWithSolar)}</p>
        </div>
        <InfoBar style={{ paddingLeft: 0, paddingRight: 20 }}>
          {property_state === "NY" && (
            <InfoRow>
              <p>New York State Tax Credit:</p>
              <p>{money(proposal?.incentive_state_tax_credit)}</p>
            </InfoRow>
          )}
          {property_state === "NY" &&
            !!(proposal?.incentive_property_tax_abatement || 0) && (
              <InfoRow>
                <p>Property Tax Abatement: </p>
                <p>{money(proposal?.incentive_property_tax_abatement || 0)}</p>
              </InfoRow>
            )}
          <InfoRow>
            <p> Total Upfront Cost to Go Solar</p>
            <p>$0.00</p>
          </InfoRow>
          {property_state === "FL" && (
            <InfoRow>
              <p> Estimated Monthly Solar Cost</p>
              <p>{money((proposal?.ms_solar_bill || 0) / 12)}</p>
            </InfoRow>
          )}
        </InfoBar>
      </div>
    </Card>
  );
}

export default SavingsByTheNumbers;

const Card = styled(CardBase)`
  position: relative;
  width: 100%;
  max-width: min(100%, calc(100vw - 96px));
  display: flex;
  flex-direction: column;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .table {
    display: grid;
    grid-template-columns: 40% 40% 20%;
    gap: 6px;
    padding: 12px 20px;

    p.cell {
      font-family: Gotham Medium;
      white-space: nowrap;
      text-align: end;
      width: 92px;
    }

    p.light {
      font-family: Gotham Book;
    }

    .column-head {
      border: 1px solid #0cceff;
      color: #0cceff;
      border-radius: 100px;
      margin-right: auto;
      transform: translate(-8px);
      padding: 2px 6px;
      white-space: nowrap;
    }

    .line {
      grid-column: span 3;
      height: 1px;
      background: #4b4b4b;

      width: calc(100% + 100px);
      transform: translate(-48px);
    }
  }
`;
