import FullPage from "@/Components_DEP/FullPage";
import styled from "@emotion/styled";

import MapButton from "./MapButton";
import HomeValueCard from "./HomeValueCard";
import { useNavigate, useParams } from "react-router";
import HomeAnimation from "./HomeAnimation";
import { OptionalLicenseNumbers } from "@/Components/LicenseNumbers";
import Context from "@/Context";
import { useContext } from "react";

function HomeValueBenefits() {
  const navigate = useNavigate();
  const { guid } = useParams();
  const { proposal } = useContext(Context);
  console.log(proposal);

  const isCT = proposal?.property_state.toUpperCase() === "CT";

  return (
    <Wrapper>
      <div className="row header">
        {isCT ? (
          <h2>Momentum in Your Hometown</h2>
        ) : (
          <h2>Home Value Benefits</h2>
        )}
        {!isCT && (
          <MapButton
            onClick={() => {
              navigate(`/${guid}/map`);
            }}
          />
        )}
      </div>
      <HomeAnimation />
      {isCT ? (
        <>
          <h3>
            Check out your neighbors who decided to go solar with Momentum too!
          </h3>
          <div style={{ margin: "auto", marginTop: "8px" }}>
            <MapButton
              onClick={() => {
                navigate(`/${guid}/map`);
              }}
              buttonText="View Your Neighborhood Map"
            />
          </div>
        </>
      ) : (
        <>
          <h3>Expect an Increase in Value to Your Home</h3>
          <div className="row end">
            <HomeValueCard
              label="Increase"
              value="+4.1%"
              text="According to Zillow, homes with solar-energy systems sold for 4.1% more on average than comparable homes without solar power. They can provide substantial future energy cost savings."
              source={{
                text: "Zillow",
                href: "https://www.zillow.com/research/solar-panels-house-sell-more-23798/",
              }}
            />
            <HomeValueCard
              label="Faster"
              value="20%"
              text="Homes with solar panels or solar systems have been proven to sell 20% faster than those without them."
              source={{
                text: "Forbes",
                href: "https://www.forbes.com/home-improvement/solar/does-solar-increase-home-value/#:~:text=appeal%20and%20value.-,How%20Does%20Solar%20Increase%20Home%20Value%3F,higher%20than%20those%20without%20them.",
              }}
            />
          </div>
        </>
      )}
      <OptionalLicenseNumbers
        orientation="horizontal"
        style={{ margin: "auto", marginTop: "8px" }}
      />
    </Wrapper>
  );
}

export default HomeValueBenefits;

const Wrapper = styled(FullPage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 116px);
  margin-bottom: 90px;

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }

  .header {
    padding-top: 70px;
  }

  h3 {
    color: #369;
    margin-bottom: 24px;
  }
`;
